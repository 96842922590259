import React from "react";
//import { Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import "bootstrap/dist/css/bootstrap.min.css";

const Skills = ({location}) => (
  <Layout location={location}>
    <SEO title="Skills" />
    <table class="skillsTable">
      <tr style={{fontSize: "200%"}}>
        <th></th>
        <th>★★★</th>
        <th>★★☆</th>
        <th>★☆☆</th>
      </tr>
      <tr>
        <th>Programming Languages</th>
        <td>C++, C#, HLSL, GLSL, HTML, CSS, PHP, JavaScript, Typescript</td>
        <td>Lua, Python, Java</td>
        <td>MEL, Assembly</td>
      </tr>
      <tr>
        <th>Platforms</th>
        <td>Windows</td>
        <td>Wii, Xbox 360, PlayStation 3</td>
        <td>Linux</td>
      </tr>
      <tr>
        <th>Tools</th>
        <td>Visual Studio, VSCode, Maya, 3ds Max, Photoshop, Gamebryo, Unity 3D</td>
        <td>
          Blender, PIX, Eclipse, Node.js, SVN, git
        </td>
        <td>Unreal Engine, Eclipse, Perforce</td>
      </tr>
      <tr>
        <th>Libraries</th>
        <td>OpenGL, WebGL, DirectX, .NET, WPF, SDL</td>
        <td>Vulkan, OpenAL, FreeType, Ogg Vorbis, Dear ImGui</td>
        <td>Win32, Qt, Angular, Maya C++ API</td>
      </tr>
    </table>

    <div>&nbsp;</div>

    <table class="skillsTable">
      <tr>
        <td colspan="2">Spoken Languages</td>
      </tr>
      <tr>
        <th>English</th>
        <td>Native</td>
      </tr>
      <tr>
        <th>Japanese</th>
        <td>Intermediate (Passed the JLPT N3 test）</td>
      </tr>
    </table>
  </Layout>
);

export default Skills;
